<template>
  <div class="introduceWrap clearFix">
    <div id="Basic_pageHeader" class="introduceMenu">
      <div>
        <ul>
          <li @click="scrollEvent(0)" @keydown="scrollEvent(0)">상품 옵션</li>
          <li @click="scrollEvent(1)" @keydown="scrollEvent(1)">상품 소개</li>
          <li @click="scrollEvent(2)" @keydown="scrollEvent(2)">작업 가이드</li>
          <li @click="scrollEvent(3)" @keydown="scrollEvent(3)">고객 리뷰</li>
        </ul>
        <div :style="moveBar" />
      </div>
    </div>
    <div id="fixed_Header" class="introduceMenu fixed">
      <div>
        <ul>
          <li @click="scrollEvent(0)" @keydown="scrollEvent(0)">상품 옵션</li>
          <li @click="scrollEvent(1)" @keydown="scrollEvent(1)">상품 소개</li>
          <li @click="scrollEvent(2)" @keydown="scrollEvent(2)">작업 가이드</li>
          <li @click="scrollEvent(3)" @keydown="scrollEvent(3)">고객 리뷰</li>
        </ul>
        <div :style="moveBar" />
      </div>
    </div>
    <div class="introduceBox">
      <div>
        <!-- stand_1 -->
        <div v-show="params === 'Stand'">
          <img src="@/assets/intro/intro_stand_1.png" alt="" />
        </div>
        <!-- stand_1 -->
        <!-- stand_2 -->
        <div v-show="params === 'Stand'">
          <img src="@/assets/intro/intro_stand_2.png" alt="" />
        </div>
        <!-- stand_2 -->
        <!-- cham_1 -->
        <div v-show="params === 'Cham'">
          <img src="@/assets/intro/intro_cham_1.png" alt="" />
        </div>
        <!-- cham_1 -->
        <!-- card_1 -->
        <div v-show="params === 'Card'">
          <img src="@/assets/intro/intro_card_1.png" alt="" />
        </div>
        <!-- card_1 -->
        <!-- bookmark_1 -->
        <div v-show="params === 'Bookmark'">
          <img src="@/assets/intro/intro_bookmark_1.png" alt="" />
        </div>
        <div v-show="params === 'LampStand'">
          <img src="@/assets/intro/intro_lamp_1.png" alt="" />
        </div>
        <div v-show="params === 'FloorParts'">
          <img src="@/assets/intro/intro_floorparts_1.png" alt="" />
        </div>
        <div v-show="params === 'Attach'">
          <img src="@/assets/intro/intro_attach_1.png" alt="" />
        </div>
        <div v-show="params === 'Block'">
          <img src="@/assets/intro/intro_block_1.png" alt="" />
        </div>
        <!-- bookmark_1 -->
        <!-- bookmark_2 -->
        <div v-show="params === 'Bookmark'">
          <img src="@/assets/intro/intro_bookmark_2.png" alt="" />
        </div>
        <div v-show="params === 'ShakerKeyring'">
          <img src="@/assets/intro/intro_shaker_1.png" alt="" />
        </div>
        <!-- bookmark_2 -->
        <!-- cham_2, stand_3 -->
        <div
          v-show="
            params !== 'Card' &&
            params !== 'Bookmark' &&
            params !== 'LampStand' &&
            params !== 'FloorParts' &&
            params !== 'Attach' &&
            params !== 'Block' &&
            params !== 'ShakerKeyring'
          "
        >
          <img src="@/assets/intro/intro_3.png" alt="" />
        </div>
        <!-- cham_2, stand_3 -->
        <!-- cham_3, stand_4, card_2 -->
        <div
          v-show="
            params !== 'Bookmark' &&
            params !== 'LampStand' &&
            params !== 'Block' &&
            params !== 'ShakerKeyring'
          "
        >
          <img src="@/assets/intro/intro_4.png" alt="" />
        </div>
        <!-- cham_3, stand_4, card_2 -->
        <!-- card_3, bookmark_3 -->
        <div v-show="params === 'Card' || params === 'Bookmark' || params === 'FloorParts'">
          <img src="@/assets/intro/intro_card_3.png" alt="" />
        </div>
        <!-- card_3, bookmark_3 -->
        <!-- cham_4, stand_5 -->
        <div
          v-show="
            params !== 'Card' &&
            params !== 'Bookmark' &&
            params !== 'LampStand' &&
            params !== 'FloorParts' &&
            params !== 'Attach' &&
            params !== 'Block' &&
            params !== 'ShakerKeyring'
          "
          class="last"
        >
          <img src="@/assets/intro/intro_5.png" alt="" />
        </div>
        <!-- cham_4, stand_5 -->
        <!-- cham_5, stand_6, card_4 -->
        <div v-show="params !== 'Block'" class="last">
          <img src="@/assets/intro/intro_6.png" alt="" />
        </div>
        <div v-show="params === 'Block'" class="last">
          <img src="@/assets/intro/intro_block_2.png" alt="" />
        </div>

        <!-- cham_5, stand_6, card_4 -->
        <div class="delivery">
          <img src="@/assets/intro/intro_delivery_1.png" alt="" />
        </div>
        <div>
          <img src="@/assets/intro/intro_delivery_2.png" alt="" />
        </div>
        <div
          v-show="
            params !== 'Bookmark' &&
            params !== 'LampStand' &&
            params !== 'Attach' &&
            params !== 'Block'
          "
        >
          <img src="@/assets/intro/intro_delivery_3.png" alt="" />
        </div>
        <div v-show="params === 'Bookmark'">
          <img src="@/assets/intro/intro_delivery_bookmark_3.png" alt="" />
        </div>
        <div v-show="params === 'LampStand'">
          <img src="@/assets/intro/intro_delivery_lamp_3.png" alt="" />
        </div>
        <div v-show="params === 'Attach'">
          <img src="@/assets/intro/intro_delivery_attach_3.png" alt="" />
        </div>
        <div v-show="params === 'Block'">
          <img src="@/assets/intro/intro_delivery_block_3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="introGuideBox">
      <div>
        <div class="guideLink" @click="goGuide()" @keydown="goGuide()">제작가이드 바로가기</div>
      </div>
    </div>
    <review-container />
  </div>
</template>

<script>
import ReviewContainer from '../../../containers/Review/ReviewContainer.vue'

export default {
  components: {
    ReviewContainer,
  },
  props: {
    params: {
      type: String,
    },
    moveBar: {
      type: Object,
    },
  },
  methods: {
    scrollEvent(i) {
      this.$emit('scrollEvent', i)
    },
    goGuide() {
      let { type } = this.$route.params

      this.$router.push({
        name: 'Guide',
        params: {
          type,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.introduceWrap {
  width: 100%;
  min-width: 1160px;
  margin: 0 auto;

  > .introduceMenu {
    width: 100%;
    min-width: 1160px;
    text-align: center;
    background-color: #fff2f7;
    height: 72px;
    line-height: 72px;

    &.fixed {
      display: none;
    }

    > div {
      width: 1160px;
      text-align: center;
      margin: 0 auto;
      position: relative;

      > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > li {
          display: inline-block;
          margin: 0 30px;
          font-size: 15px;
          color: #707070;
          cursor: pointer;
        }
      }
    }
  }

  > .introduceBox {
    width: 100%;
    min-width: 1160px;
    margin: 0 auto;
    padding-top: 100px;

    > div {
      > div {
        text-align: center;
        padding-top: 85px;

        &.last {
          padding-bottom: 100px;
        }
      }
    }
  }

  > .introGuideBox {
    width: 1160px;
    margin: 400px auto 400px;

    > div {
      text-align: center;
      width: 100%;
      height: 92px;
      line-height: 92px;
      border: 1px solid #ff6699;
      border-radius: 10px;
      background-color: #fedbe8;
      font-size: 23px;
      color: #707070;
      cursor: pointer;
    }
  }

  > .scrolled {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
  }
}
</style>

<template>
  <div class="ai_caution">
    <h1>일러스트(AI)로 작업 시 유의 사항</h1>
    <!-- type === '아크릴 키링' -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_AI_Caution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_AI_Caution_2.png" alt="" />
        </div>
        <div class="right">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 커팅 패스</p>
          <p>4. 뒷면(있는 경우)</p>
          <p>5. 외경</p>
          <p>6. 내경</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 카드텍' -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_AI_Caution_1.png" alt="" />
        </div>
        <div class="card_img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 <span>Y100</span> K0)으로 칠해주세요.<br />일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_AI_Caution_2.png" alt="" />
        </div>
        <div class="right Card">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 커팅 패스</p>
          <p>4. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 스탠드' -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_AI_Caution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_AI_Caution_2.png" alt="" />
        </div>
        <div class="right Stand">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 커팅 패스</p>
          <p>4. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '자유형 책갈피' -->
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/bookmark_AI_Caution_1.png" alt="" />
        </div>
        <div class="img_1 right bookmark">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
          <p>4. 접는 선은 새 레이어에 붉은색 직선(1~2px)로 표기합니다.</p>
          <p>5. 접히는 바는 세로 30~50mm로 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/bookmark_AI_Caution_2.png" alt="" />
        </div>
        <div class="right bookmark">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 접는 선</p>
          <p>4. 칼선 패스</p>
          <p>5. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 바닥 부품' -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left floor">
          <img src="@/assets/guide/floor_AI_Caution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 floor img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_AI_Caution_2.png" alt="" />
        </div>
        <div class="right">
          <p>1. 구멍</p>
          <p>2. 일러스트</p>
          <p>3. 화이트</p>
          <p>4. 커팅 패스</p>
          <p>5. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 램프' -->
    <div v-show="type === '아크릴 램프'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_AI_Caution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;일러스트보다
            0.1~0.2mm 정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_AI_Caution_2.png" alt="" />
        </div>
        <div class="right Stand">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 커팅 패스</p>
          <p>4. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '부착형 아크릴' -->
    <div v-show="type === '부착형 아크릴'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_AI_Caution_1.png" alt="" />
        </div>
        <div class="attach_img_1 right">
          <p>1. 일러스트보다 0.1 ~ 0.2mm 정도 작게 만들어주세요.</p>
          <p>2. 커팅 패스를 일러스트와 2mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_AI_Caution_2.png" alt="" />
        </div>
        <div class="right Stand">
          <p>1. 위치</p>
          <p>2. 일러스트</p>
          <p>3. 화이트</p>
          <p>4. 커팅</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 블럭' -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_AI_Caution_1.png" alt="" />
        </div>
        <div class="block_img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />일러스트보다 0.1~0.2mm
            정도 작게 만들어주세요.
          </p>
          <p>3. 커팅 패스를 일러스트와 3mm 정도 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_AI_Caution_2.png" alt="" />
        </div>
        <div class="right Stand">
          <p>1. 일러스트</p>
          <p>2. 화이트</p>
          <p>3. 커팅</p>
          <p>4. 뒷면(있는 경우)</p>
          <p>레이어 작업은 위와 같이 해주시기 바랍니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '쉐이커 키링' -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_AI_Caution_1.png" alt="" />
        </div>
        <div class="shaker_keyring_img_1 right">
          <p>1. 후면에 텍스트가 들어간 경우, '텍스트'가 반전되어 있어야 정상적으로 인쇄됩니다.</p>
          <p>
            2. 화이트 레이어는 노란색(C0 M0 Y100 K0)으로 칠해주세요.<br />일러스트보다 0.1~0.2mm
            정도 작게 만들어주세요.
          </p>
          <p>3. 프레임 구멍은 3.2mm로 제작합니다.</p>
          <p>4. 커팅 패스 안에 파츠를 배치합니다.</p>
          <p>5. 커팅 패스 사이는 1mm 정도 여유가 있도록 제작합니다.</p>
          <p>6. 판 커팅과 파츠 커팅 패스 사이는 4mm 여유가 있도록 제작합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_2 img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_AI_Caution_2.png" alt="" />
        </div>
        <div class="right shaker_keyring">
          <ul>
            <li>1. 내경레이어</li>
            <li>2. 외경레이어</li>
            <li>3. 프레임 구멍(2개 이상)</li>
            <li>
              4. 쉐이커 파츠
              <ul>
                <li>- 일러스트</li>
                <li>- 화이트</li>
                <li>- 커팅</li>
              </ul>
            </li>
            <li>
              5. 쉐이커 앞면
              <ul>
                <li>- 일러스트(있을 경우)</li>
                <li>- 화이트(있을 경우)</li>
                <li>- 커팅</li>
                <li>- 판 커팅</li>
              </ul>
            </li>
            <li>
              6. 쉐이커 뒷면
              <ul>
                <li>- 일러스트(있을 경우)</li>
                <li>- 화이트(있을 경우)</li>
                <li>- 커팅</li>
              </ul>
            </li>
            <li>레이어 작업은 위와 같이 해주시기 바랍니다.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.ai_caution {
  text-align: center;
  h1 {
    width: 100%;
  }
  > .typeWrap {
    > div {
      margin-top: 40px;

      > img {
        margin-top: 20px;
      }

      > .img {
        margin-top: 30px;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 50px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        > div {
          float: left;
          margin-left: 20px;
          text-align: left;

          &.floor {
            margin-top: 73px;
          }

          &.bookmark {
            > p {
              margin-top: 40px;
            }
          }

          &.card_img_1 {
            > p {
              &:first-child {
                margin-top: 0;
              }
              margin-top: 40px;
            }
          }

          &.attach_img_1 {
            margin-top: 70px;
          }
          &.block_img_1 {
            margin-top: 20px;
          }

          &.shaker_keyring_img_1 {
            margin-top: 20px;
            > p {
              margin-top: 40px;
            }
          }
        }
      }

      &.img_2 {
        &.floor {
          margin-top: 100px;
        }
        > .left {
          margin-left: 240px;
        }

        > .right {
          margin-left: 70px;
          margin-top: 50px;

          &.Stand {
            margin-top: 100px;
          }

          &.Card {
            margin-top: 100px;
          }

          &.bookmark {
            margin-top: 80px;

            > p {
              margin-top: 20px;
            }
          }

          &.shaker_keyring {
            margin-top: 0;
            > ul {
              > li {
                font-size: 20px;
                color: #707070;
                font-weight: 500;
                margin-top: 30px;

                &:first-child {
                  margin-top: 0;
                }

                > ul {
                  margin-left: 20px;
                }
              }
            }
          }

          > p {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>

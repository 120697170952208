/*
   author : 새녘빛
   title : 동네공작소 굿즈 계산식
   version : 1.4.0
   date : 2024 06 14
   notes:
   2024 06 14. 1.4.0 다겹아크릴(쉐이커) 계산식 추가
   2024 04 03. 1.3.0 카라비너, 관람차, 15T 계산식 추가, 빠른제작 추가
   2022 03 21. 1.2.0 링트설정, 바닥가격 반환식 추가, 부자재 제외 가격 확인
   2022 03 10. 1.1.0 홀로그램 계산식 추가
   2022 02 18. 1.0.0 계산식 재정립
*/

let data = {
  thicknessPrice: {
    // 두께별 판재 비용 상수
    '15t': 19550,
    '10t': 13033,
    '5t': 6517,
    '3t': 3910,
    '1t': 1304,
    '0.5t': 1304,
  },
  cuttime: {
    // 절삭 소요 시간 비
    '15t': 4.5,
    '10t': 3,
    '5t': 1.9,
    '3t': 1,
    '1t': 0.5,
    '0.5t': 0.5,
  },
  printings: {
    // 인쇄 소요 시간 비(분)
    None: 0,
    CMYK: 9,
    CMYK_bi: 5,
    CMYKW: 17,
    CMTKW_bi: 10,
    White: 5,
    primer: 5,
    wating: 2,
  },
  prices: {
    // 기본 설정 가격
    printing: 75000,
    cut: 75000,
    hologram: 20000,
    shell: 20000,
    block: 20000,
    shellblock: 50000,
    acrylicRatio: 2, // 아크릴판재배율
    laborCost: 10000, // 인건비기준
    laborRatio: 1, // 배율
    cutMargin: 3,
    priceMargin: 1,
    marginH: 2,
    marginW: 100,
    memberMargin: 0.3,
    designFee: 5000, // 도안비
  },
  products: {
    // 인쇄, 생산 방식
    standardClear: ['CMYKW', 'primer', 'wating'], // 단면
    standardWhite: ['CMYK', 'primer', 'wating'],
    standardBothsidesThin: ['CMYK', 'CMYKW', 'primer', 'wating'],
    standardBothsidesThick: ['CMYKW', 'White', 'CMYKW', 'primer', 'wating'], // 양면
    cutOnly: ['None'],
  },
  plateSizes: {
    width: 290,
    height: 590,
    Owidth: 300,
    Oheight: 600,
  },
  materials: {
    // 부자재
    none: 0,
    jumpRing: 100,
    ballchain: 200,
    colorBallchain: 200,
    colorWiring: 300,
    phoneStrap: 200,
    badge: 250,
    keyRing: 700,
    smartTok: 800,
    opp: 120,
    boltset: 150,
  },
  opp: {
    none: 0,
    opp: 120,
  },
  packing: {
    // 조립여부
    none: 0,
    assembly: 150,
    packing: 300,
  },
  bottoms: {
    none: 0,
    '4cm': 800,
    '6cm': 1200,
    '8cm': 1600,
    lamp: 20000,
    dwarf: 8000,
  },
  productPrices: {
    canvas: 20000, // 캔버스 가격
    bigwheel: 25000, // 관람차 가격
    // acrylicBlock: 20000, // 아크릴 블럭
  },
}

// 인쇄 시간 합산
let time2make = async (products) => {
  // eslint-disable-next-line no-prototype-builtins
  if (data.products.hasOwnProperty(products)) {
    let sum = 0
    for (let i = 0; i < data.products[products].length; i++) {
      sum += data.printings[data.products[products][i]]
    }
    return sum
  }
  return false
}

// 인쇄 시간 합산에 따른 판당 인쇄 비용 계산
let platePrinting = async (products) => {
  // eslint-disable-next-line no-prototype-builtins
  if (data.products.hasOwnProperty(products)) {
    return ((await time2make(products)) / 60) * data.prices.printing
  }
  return false
}

// 판당 개수 계산
let calQty = async (width, height) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(width) || isNaN(height)) {
    return false
  }
  return (
    (data.plateSizes.width * data.plateSizes.height) /
    (width + data.prices.cutMargin) /
    (height + data.prices.cutMargin)
  )
}

// 판당 노동 비용
let laborCost4Plate = async (products) => {
  // eslint-disable-next-line no-prototype-builtins
  if (data.products.hasOwnProperty(products)) {
    if (products === 'cutOnly') {
      return 5000
    }
    return ((await time2make(products)) / 60) * data.prices.laborCost * data.prices.laborRatio
  }
  return false
}

// 인쇄 시간에 따른 개당 비용
let printPricing = async (width, height, products) => {
  return (await platePrinting(products)) / (await calQty(width, height))
}

// 절삭 시간에 따른 개당 비용
let cuttingPricing = async (width, height, thickness) => {
  return (
    (data.prices.cut * data.cuttime[thickness] * (1 / width + 1 / height)) /
    (1 / data.plateSizes.Owidth + 1 / data.plateSizes.Oheight) /
    60 /
    (await calQty(width, height))
  )
}

// 판재 소비에 따른 개당 비용
let platePricing = async (width, height, thickness) => {
  return (data.thicknessPrice[thickness] * data.prices.acrylicRatio) / (await calQty(width, height))
}

// 노동에 따른 개당 비용
let laborPricing = async (width, height, products) => {
  return (await laborCost4Plate(products)) / (await calQty(width, height))
}

// 홀로그램 계산식
let hologramPricing = async (width, height) => {
  return data.prices.hologram / (await calQty(width, height))
}

// 쉘 아크릴 계산식
let shellPricing = async (width, height) => {
  return data.prices.shell / (await calQty(width, height))
}

// 블럭 아크릴 계산식
let blockPricing = async (width, height) => {
  return data.prices.block / (await calQty(width, height))
}

// 날것 비용 계산식
let rawPrice = async (width, height, products, thickness, options) => {
  let hologramPrice = 0
  let shellPrice = 0
  let blockPrice = 0
  let carabinerPrice = 0
  if (options) {
    if (options.hologram) {
      hologramPrice = await hologramPricing(width, height)
    }
    if (options.shell) {
      shellPrice = await shellPricing(width, height)
    }
    if (options.block) {
      blockPrice = await blockPricing(width, height)
    }
    if (options.carabiner) {
      carabinerPrice = 500
    }
  }

  const price =
    (await printPricing(width, height, products)) +
    (await cuttingPricing(width, height, thickness)) +
    (await platePricing(width, height, thickness)) +
    (await laborPricing(width, height, products)) +
    hologramPrice +
    shellPrice +
    blockPrice +
    carabinerPrice

  return price
}

// 마진율에 따른 계산식
let qtyMargin = async (width, height, products, thickness, qty, options, layers) => {
  let rawPrice0 = 0
  if (layers.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for await (const layer of layers) {
      rawPrice0 += await rawPrice(width, height, layer[1], layer[0], layer[2])
    }
  }

  rawPrice0 += await rawPrice(width, height, products, thickness, options)
  if (options.quick_order) {
    // eslint-disable-next-line no-unused-expressions
    rawPrice0 *= 0.9
  }
  rawPrice0 +=
    // eslint-disable-next-line no-restricted-properties
    // 1개일때 2배, 10개일때 1.5배, 100개일 때 1.25배
    (rawPrice0 * 1) / (data.prices.marginH ** (Math.log(qty) / Math.log(data.prices.marginW)) * 3)
  return rawPrice0
}

// 부자재에 따른 비용 추가
let sumEtc = async (opp, item, packing) => {
  let sum = 0
  if (opp === 1) {
    sum += data.opp.opp * 1
  }
  if (item === 'O링') {
    sum += data.materials.jumpRing * 1
  } else if (item === '군번줄(일반)') {
    sum += data.materials.ballchain * 1
  } else if (item.indexOf('군번줄') > -1) {
    sum += data.materials.colorBallchain * 1
  } else if (item === '휴대폰줄') {
    sum += data.materials.phoneStrap * 1
  } else if (item.indexOf('고리') > -1) {
    sum += data.materials.keyRing * 1
  } else if (item.indexOf('뱃지') > -1) {
    sum += data.materials.badge * 1
  } else if (item.indexOf('스마트톡') > -1) {
    sum += data.materials.smartTok * 1
  } else if (item.indexOf('와이어링') > -1) {
    sum += data.materials.colorWiring * 1
  }
  if (packing === '부자재조립') {
    sum += data.packing.assembly * 1
  } else if (packing === '개별포장') {
    sum += data.packing.packing * 1
  }
  return sum
}

// width: xsize, height:ysize, products:인쇄면, thickness:두께, qty:수량
// 아크릴 상품의 가격 총 계산식
let memberMargin = async (
  width,
  height,
  products,
  thickness,
  qty,
  opp,
  etc,
  packing,
  bottom,
  options,
  layers,
) => {
  let sum = (await sumEtc(opp, etc, packing)) * 1 + data.bottoms[bottom] * 0.7
  let qtyM = await qtyMargin(width, height, products, thickness, qty, options, layers)
  if (
    // eslint-disable-next-line no-restricted-globals
    !isNaN(width) &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(height) &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(qty) &&
    // eslint-disable-next-line no-prototype-builtins
    data.products.hasOwnProperty(products) &&
    // eslint-disable-next-line no-prototype-builtins
    data.cuttime.hasOwnProperty(thickness)
  ) {
    return (await qtyM) * (1 + data.prices.memberMargin) + sum
  }
  return false
}
/*
   기본 가격 반환 함수
   x(num) : 가로길이
   y(num) : 세로길이
   thick(str) : 두께 - "5t", "3t", "1t", "0.5t"
   item(str) : 부자재 - "O링", "군번줄", "휴대폰줄", "키링"
   item_opp(bool) : opp 유무
   packing(str) : 포장방법 - "부자재 포장", "개별 포장"
   print(str) : 인쇄면
   design_quantity(num) : 디자인 개수
   order_quantity(num) : 주문 개수
   options(object) : 추가옵션 (홀로그램 등)
    - hologram(bool) : 홀로그램 유무
   layers(array) : 추가 겹 아크릴 두께와 인쇄종류와 판재종류. 없으면 빈 배열[] 넣기  ex) [["3t","단면","홀로그램"],["3t","단면", "투명 쉘"],["1t","없음", "없음"]]
*/
const normal = async (order, type) => {
  let fullPrice = 0
  let price4one = 0
  let xsize = order.x * 10
  let ysize = order.y * 10
  let {
    thick, // 두께
    stand, // 특수스탠드 여부(우드램프, 오뚜기)
    etc, // 부자재
    kind, // 아크릴판재종류 (쉘, 홀로그램)
    shape, // 형태 (카라비너)
    item_opp, // opp 유무
    packing, // 포장방법
    print, // 인쇄면
    order_quantity, // 디자인개수
    tag_color, // 아크릴군번줄색상
    design_quantity, // 디자인종류
    design_request, // 디자인 요청 유무
    period, // 빠른제작 유무
    second_print, // 두번째 인쇄면,
    parts_print, // 파츠 인쇄면,
    bolt_set, // 쉐이커 볼트 개수
  } = order
  let ceil = 100
  let vat = 10
  let layers = []

  if (type === '쉐이커 키링' && second_print) {
    thick = '1t'
    layers = [
      ['1t', second_print, '투명'],
      ['3t', parts_print, '투명'],
    ]
  }

  let request = 0

  if (design_request === true) {
    request = design_quantity * 10000
  }

  let design = design_quantity * data.prices.designFee
  let side
  if (print === '단면') {
    side = 'standardClear'
  } else if (print === '양면') {
    side = 'standardBothsidesThick'
  } else if (print === '없음') {
    side = 'cutOnly'
  }

  let options = {
    hologram: false,
    shell: false,
    block: false,
    carabiner: false,
    quick_order: false,
  }

  layers.forEach((layer) => {
    // 다겹아크릴 옵션명 정리 구문
    if (layer[1] === '단면') {
      layer[1] = 'standardClear'
    } else if (layer[1] === '양면') {
      layer[1] = 'standardBothsidesThick'
    } else {
      layer[1] = 'cutOnly'
    }
    if (layer[2] === '홀로그램') {
      layer[2] = { hologram: true }
    } else if (layer[2] === '투명 쉘') {
      layer[2] = { shell: true }
    } else {
      layer[2] = false
    }
  })

  if (kind === '홀로그램') {
    options.hologram = true
  } else {
    options.hologram = false
  }
  if (kind === '투명 쉘') {
    options.shell = true
  } else {
    options.shell = false
  }

  if (shape) {
    options.carabiner = true
  } else {
    options.carabiner = false
  }
  if (period === '빠른제작') {
    options.quick_order = true
  } else {
    options.quick_order = false
  }

  if (thick === '10t' || thick === '15t') {
    options.block = true
  } else {
    options.block = false
  }

  if (etc.indexOf('군번줄') > -1) {
    etc += `(${tag_color})`
  }

  if (stand === '없음') {
    stand = 'none'
  } else if (stand.indexOf('우드 램프') > -1) {
    stand = 'lamp'
  } else if (stand === '오뚝이 아크릴') {
    stand = 'dwarf'
  }

  const mm = await memberMargin(
    xsize,
    ysize,
    side,
    thick,
    order_quantity,
    item_opp,
    etc,
    packing,
    stand,
    options,
    layers,
  )
  // 볼트세트 금액 추가
  let bolt_price = 0
  if (bolt_set) {
    bolt_price = bolt_set * data.materials.boltset
  }
  let fm = mm * order_quantity + design + bolt_price
  // 1개 계산식 추가
  let forone = mm * 1 + data.prices.designFee + bolt_price

  fullPrice = Math.ceil((fm + (fm * vat) / 100) / ceil) * ceil + request

  price4one = Math.ceil((forone + (forone * vat) / 100) / ceil) * ceil + request

  return { fullPrice, price4one }
}

// 캔버스 가격 반환 함수
const canvas = async (order) => {
  let price = 0
  price = data.productPrices.canvas * order
  return price
}

// 관람차 가격 반환 함수
// thick: 두께. "5t", "3t"
// size: 크기. "큰관람차", "일반관람차"
// print: 인쇄방식. "전체양면", "키링양면", "스탠드양면", "단면"
// order_quantity: 개수. num
const bigwheel = async (order) => {
  let { thick, print, second_print, order_quantity, wheel } = order
  let price = 0
  if (thick === '5t') {
    price += 5000
  }

  if (print === '양면') {
    price += 5000
  }

  if (second_print === '양면') {
    price += 5000
  }

  // wheel: '5인용', '6인용', '7인용'

  if (wheel === '6인용') {
    price += 7000
  } else if (wheel === '7인용') {
    price += 12000
  }
  // price += data.productPrices.bigwheel * order_quantity
  const wheelPrice = (price + data.productPrices.bigwheel) * order_quantity
  return wheelPrice
}

// 아크릴 블럭 가격 반환 함수
// const block = async (order) => {
//   let price = 0
//   price = data.productPrices.acrylicBlock * order
//   return price
// }

// 부자재 가격 반환
const items = async (order) => {
  let price = 0
  let { order_quantity } = order
  if (order.item.indexOf('키링') === -1) {
    if (order.item.indexOf('O링') > -1) {
      price = data.materials.jumpRing * order_quantity
    } else {
      price = data.materials.ballchain * order_quantity
    }
  } else {
    price = data.materials.keyRing * order_quantity
  }
  return price
}

// 바닥 가격 반환 - "3T", "5T", "1T", "0.5T"
const bottomPrice = async (order) => {
  return data.bottoms[order]
}

// 티켓 패키지 반환
const ticket = async (order) => {
  let price = 0
  let { order_quantity } = order
  price = order_quantity * 12000
  return price
}

// 티켓 단품 반환
const single_ticket = async (order) => {
  let price = 0
  let { order_quantity } = order
  price = order_quantity * 7000
  return price
}

// 기본 아크릴 가격
module.exports.normal = normal
// 부자재 제외 아크릴 가격
module.exports.rawPrice = rawPrice
// 바닥부품 값 반환
module.exports.bottomPrice = bottomPrice
// 부자재 가격
module.exports.items = items
// 캔버스 가격
module.exports.canvas = canvas
// 관람차 가격
module.exports.bigwheel = bigwheel
// 아크릴 블록 가격
// module.exports.block = block
// 티켓
module.exports.ticket = ticket
module.exports.single_ticket = single_ticket

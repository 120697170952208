import { normal, canvas, bigwheel } from '../../plugins/Formula'

const defaultPeriod = 12
const quickPeriod = 5
const shellPeriod = 17

export const Goods = {
  default_period: defaultPeriod,
  quick_period: quickPeriod,
  shell_default_period: shellPeriod,
  quick_order: true,
  namespaced: true,
  production_period: defaultPeriod, // 제작기간
  shell_period: shellPeriod, // 쉘 제작기간
  state: () => ({
    type: '',
    goods: {
      x: 4,
      y: 4,
      thick: '3t',
      stand: '없음',
      print: '단면',
      etc: '없음',
      kind: '투명',
      shape: null,
      wheel: null,
      bolt_set: null,
      period: '일반제작',
      second_print: null,
      parts_print: null,
      item_opp: false,
      tag_color: null,
      wiring_color: null,
      keyring_option: null,
      keyring_color: null,
      tok_color: null,
      badge_kind: null,
      packing: '조립없음',
      design_quantity: 1,
      order_quantity: 1,
      design_request: false,
      file: '',
      price: 0,
      fullPrice: 0,
      price4one: 0,
      percentage: 0,
    },
  }),
  mutations: {
    GOODS_RESET(state, data) {
      state.goods = data
      this.dispatch('Goods/fm', state)
    },
    CANVAS_RESET(state, data) {
      state.goods = data
      this.dispatch('Goods/canvasFm', data.order_quantity)
    },
    LOCATION_SIZE(state, data) {
      state.goods.x = data.x
      state.goods.y = data.y
      if (state.type === '아크릴 관람차') {
        this.dispatch('Goods/wheelFm', state.goods)
      } else {
        this.dispatch('Goods/fm', state)
      }
    },
    THICK_SEL(state, data) {
      state.goods.thick = data
      if (state.type === '아크릴 관람차') {
        this.dispatch('Goods/wheelFm', state.goods)
      } else {
        this.dispatch('Goods/fm', state)
      }
    },
    KIND_SEL(state, data) {
      state.goods.kind = data
      this.dispatch('Goods/fm', state)
    },
    STAND_SEL(state, data) {
      state.goods.stand = data
      this.dispatch('Goods/fm', state)
    },
    PRINT_SEL(state, data) {
      state.goods.print = data
      if (state.type === '아크릴 관람차') {
        this.dispatch('Goods/wheelFm', state.goods)
      } else {
        this.dispatch('Goods/fm', state)
      }
    },
    ITEM_SEL(state, data) {
      state.goods.etc = data
      if (state.type === '아크릴 관람차') {
        this.dispatch('Goods/wheelFm', state.goods)
      } else {
        this.dispatch('Goods/fm', state)
      }
    },
    TAG_COLOR_SEL(state, data) {
      state.goods.tag_color = data
      this.dispatch('Goods/fm', state)
    },
    WIRING_COLOR_SEL(state, data) {
      state.goods.wiring_color = data
      this.dispatch('Goods/fm', state)
    },
    TOK_COLOR_SEL(state, data) {
      state.goods.tok_color = data
      this.dispatch('Goods/fm', state)
    },
    BADGE_KIND_SEL(state, data) {
      state.goods.badge_kind = data
      this.dispatch('Goods/fm', state)
    },
    KEYRING_KIND_SEL(state, data) {
      state.goods.keyring_option = data
    },
    KEYRING_COLOR_SEL(state, data) {
      state.goods.keyring_color = data
    },
    PACKING_SEL(state, data) {
      state.goods.packing = data
      if (state.type === '아크릴 관람차') {
        this.dispatch('Goods/wheelFm', state.goods)
      } else {
        this.dispatch('Goods/fm', state)
      }
    },
    QUANTITY_SEL(state, data) {
      state.goods.design_quantity = data.design_quantity
      state.goods.order_quantity = data.order_quantity
      if (data.path === 'normal') {
        this.dispatch('Goods/fm', state)
      } else if (data.path === 'canvas') {
        this.dispatch('Goods/canvasFm', state.goods.order_quantity)
      } else if (data.path === 'wheel') {
        this.dispatch('Goods/wheelFm', state.goods)
      }
    },
    FILE_SEL(state, data) {
      state.goods.file = data
    },
    REQUEST_SEL(state, data) {
      state.goods.design_request = data
      this.dispatch('Goods/fm', state)
    },
    PRICE_CALCULATE(state, data) {
      state.goods.price = data.price
      state.goods.fullPrice = data.fullPrice
      state.goods.price4one = data.price4one
      state.goods.percentage = data.percentage
    },
    TYPE_SET(state, data) {
      state.type = data
    },
    SHAPE_SET(state, data) {
      state.goods.shape = data
      this.dispatch('Goods/fm', state)
    },
    WHEEL_SET(state, data) {
      state.goods.wheel = data
      this.dispatch('Goods/wheelFm', state.goods)
    },
    BOLT_SET(state, data) {
      state.goods.bolt_set = data
      this.dispatch('Goods/fm', state)
    },
    SECOND_PRINT_SET(state, data) {
      state.goods.second_print = data
      if (state.type === '쉐이커 키링') {
        this.dispatch('Goods/fm', state)
      } else {
        this.dispatch('Goods/wheelFm', state.goods)
      }
    },
    PARTS_PRINT_SET(state, data) {
      state.goods.parts_print = data
      this.dispatch('Goods/fm', state)
    },
    // 빠른제작 체크
    QUICK_ORDER_CHECK(state, data) {
      state.quick_order = data
    },
    PERIOD_SET(state, data) {
      state.goods.period = data
      if (data === '빠른제작') {
        state.production_period = quickPeriod
        state.goods.kind = '투명'
        if (state.type === '부착형 아크릴' || state.type === '아크릴 카드텍') {
          state.goods.packing = '묶음포장'
        } else {
          state.goods.packing = '조립없음'
        }
      } else {
        state.production_period = defaultPeriod
      }
      this.dispatch('Goods/fm', state)
    },
    PERIOD_RESET(state) {
      state.production_period = defaultPeriod
      state.shell_period = shellPeriod
      state.default_period = defaultPeriod
      state.shell_default_period = shellPeriod
      state.quick_period = quickPeriod
    },
  },
  actions: {
    reset({ commit }) {
      let goods = {
        x: 4,
        y: 4,
        thick: '3t',
        stand: '없음',
        print: '단면',
        etc: '없음',
        kind: '투명',
        shape: null,
        wheel: null,
        bolt_set: null,
        period: '일반제작',
        second_print: null,
        parts_print: null,
        item_opp: false,
        tag_color: null,
        wiring_color: null,
        keyring_option: null,
        keyring_color: null,
        tok_color: null,
        badge_kind: null,
        packing: '조립없음',
        design_quantity: 1,
        order_quantity: 1,
        design_request: false,
        file: '',
        price: 0,
        fullPrice: 0,
        price4one: 0,
        percentage: 0,
      }
      commit('GOODS_RESET', goods)
    },
    canvasReset({ commit }) {
      let goods = {
        x: 16,
        y: 23,
        thick: '없음',
        stand: '없음',
        print: '단면',
        etc: '없음',
        kind: '투명',
        shape: null,
        wheel: null,
        bolt_set: null,
        period: '일반제작',
        second_print: null,
        parts_print: null,
        item_opp: false,
        tag_color: null,
        wiring_color: null,
        keyring_option: null,
        keyring_color: null,
        tok_color: null,
        badge_kind: null,
        packing: '개별포장',
        design_quantity: 1,
        order_quantity: 1,
        design_request: false,
        file: '',
        price: 0,
        fullPrice: 0,
        price4one: 0,
        percentage: 0,
      }
      commit('CANVAS_RESET', goods)
    },
    wheelReset({ commit }) {
      let goods = {
        x: 12,
        y: 12,
        thick: '3t',
        stand: '없음',
        print: '단면',
        etc: 'OPP',
        kind: '투명',
        shape: null,
        wheel: '5인용',
        bolt_set: null,
        period: '일반제작',
        second_print: '단면',
        parts_print: null,
        item_opp: false,
        tag_color: null,
        wiring_color: null,
        keyring_option: null,
        keyring_color: null,
        tok_color: null,
        badge_kind: null,
        packing: '묶음포장',
        design_quantity: 1,
        order_quantity: 1,
        design_request: false,
        file: '',
        price: 0,
        fullPrice: 0,
        price4one: 0,
        percentage: 0,
      }
      commit('CANVAS_RESET', goods)
    },
    periodReset({ commit }) {
      commit('PERIOD_RESET')
    },
    pSize({ commit }, data) {
      commit('LOCATION_SIZE', data)
    },
    thick({ commit }, data) {
      commit('THICK_SEL', data)
    },
    kind({ commit }, data) {
      commit('KIND_SEL', data)
    },
    stand({ commit }, data) {
      commit('STAND_SEL', data)
    },
    print({ commit }, data) {
      commit('PRINT_SEL', data)
    },
    item({ commit }, data) {
      commit('ITEM_SEL', data)
    },
    tagColor({ commit }, data) {
      commit('TAG_COLOR_SEL', data)
    },
    wiringColor({ commit }, data) {
      commit('WIRING_COLOR_SEL', data)
    },
    tokColor({ commit }, data) {
      commit('TOK_COLOR_SEL', data)
    },
    badgeKind({ commit }, data) {
      commit('BADGE_KIND_SEL', data)
    },
    keyring_kind({ commit }, data) {
      commit('KEYRING_KIND_SEL', data)
    },
    keyring_color({ commit }, data) {
      commit('KEYRING_COLOR_SEL', data)
    },
    packing({ commit }, data) {
      commit('PACKING_SEL', data)
    },
    quantity({ commit }, data) {
      commit('QUANTITY_SEL', data)
    },
    file({ commit }, data) {
      commit('FILE_SEL', data)
    },
    request({ commit }, data) {
      commit('REQUEST_SEL', data)
    },
    shape({ commit }, data) {
      commit('SHAPE_SET', data)
    },
    wheel({ commit }, data) {
      commit('WHEEL_SET', data)
    },
    bolt({ commit }, data) {
      commit('BOLT_SET', data)
    },
    second_print({ commit }, data) {
      commit('SECOND_PRINT_SET', data)
    },
    parts_print({ commit }, data) {
      commit('PARTS_PRINT_SET', data)
    },
    period({ commit }, data) {
      commit('PERIOD_SET', data)
    },

    async fm({ commit }, data) {
      const { fullPrice, price4one } = await normal(data.goods, data.type)
      const price = Math.ceil(fullPrice / data.goods.order_quantity)
      // const price4one = await normal(data.goods, data.type)
      const percentage = 100 - Math.floor((price / price4one) * 100)
      const value = {
        price,
        fullPrice,
        price4one,
        percentage,
      }
      commit('PRICE_CALCULATE', value)
    },
    async canvasFm({ commit }, data) {
      const price = await canvas(data)
      const fullPrice = price
      const price4one = 0
      const percentage = 0
      let value = {
        price,
        fullPrice,
        price4one,
        percentage,
      }
      commit('PRICE_CALCULATE', value)
    },
    async wheelFm({ commit }, data) {
      const price = await bigwheel(data)
      const fullPrice = price
      const price4one = 0
      const percentage = 0
      let value = {
        price,
        fullPrice,
        price4one,
        percentage,
      }
      commit('PRICE_CALCULATE', value)
    },
    // 주문상품 타입 저장
    typeSet({ commit }, data) {
      commit('TYPE_SET', data)
    },
    // 빠른제작 가능 여부 체크
    quickOrderCheck({ commit }, data) {
      commit('QUICK_ORDER_CHECK', data)
    },
  },
  getters: {
    goods(state) {
      return state.goods
    },
    type(state) {
      return state.type
    },
    quick_order(state) {
      return state.quick_order
    },
    production_period(state) {
      return state.production_period
    },
    shell_period(state) {
      return state.shell_period
    },
    default_period(state) {
      return state.default_period
    },
    shell_default_period(state) {
      return state.shell_default_period
    },
    quick_period(state) {
      return state.quick_period
    },
  },
}

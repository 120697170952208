<template>
  <div class="Wrap">
    <div class="goodsWrap clearFix">
      <basic-left
        :name="name"
        :type="type"
        :thick-num="thickNum"
        :kind-num="kindNum"
        :stand-num="standNum"
        :print-num="printNum"
        :sec-print-num="secPrintNum"
        :parts-print-num="partsPrintNum"
        :item-num="itemNum"
        :period-num="periodNum"
        :wire-color-num="wireColorNum"
        :tag-color-num="tagColorNum"
        :tok-color-num="tokColorNum"
        :badge-kind-num="badgeKindNum"
        :packing-num="packingNum"
        :keyring-kind-num="keyringKindNum"
        :keyring-color-num="keyringColorNum"
        :x-length="xLength"
        :y-length="yLength"
        :preview-xsize="previewXsize"
        :preview-ysize="previewYsize"
        :speech-bubble="speechBubble"
        :shaker-size-hole="shakerSizeHole"
        @cart-add="cartAdd"
        @sel-option="selOption"
        @size-change="sizeChange"
        @location="location"
        @location-over="locationOver"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
        @select-file="selectFile"
        @speech-view="speechView"
        @speech-hide="speechHide"
        @print-popup="printPopup"
        @wiring-popup="wiringPopup"
      />
      <basic-right />
    </div>
    <div class="margin" />
    <basic-intro :params="params" :move-bar="intro_movebar" @scroll-event="scrollEvent" />
    <stand-none-letter v-if="stand_none === true" @close-tab="closeTab()" />
    <print-shell v-if="print_shell_popup === true" @close-tab="closeTab()" />
    <wiring-popup v-if="wiring_popup === true" @close-tab="closeTab()" />
    <period-modal v-if="period_popup === true" @close-tab="closeTab()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicLeft from '../../components/Goods/Menu/BasicLeft.vue'
import BasicRight from '../../components/Goods/Menu/BasicRight.vue'
import BasicIntro from '../../components/Goods/Intro/IntroBasic.vue'
import StandNoneLetter from '../../components/Modal/StandNoneLetter.vue'
import PrintShell from '../../components/Modal/PrintShell.vue'
import WiringPopup from '../../components/Modal/ItemWiring.vue'
import PeriodModal from '../../components/Modal/PeriodModal.vue'

export default {
  components: {
    BasicLeft,
    BasicRight,
    BasicIntro,
    StandNoneLetter,
    PrintShell,
    WiringPopup,
    PeriodModal,
  },
  data() {
    return {
      params: '', // 현재 페이지의 params
      // type: '',
      intro_movebar: {
        position: 'absolute',
        width: '100px',
        height: '4px',
        backgroundColor: '#ff6699',
        borderRadius: '10px',
        top: '68px',
        left: '205px',
        transition: '0.5s',
      },
      headerTop: null, // intro 기준 높이
      introHeight: 0, // params에 따른 margin값
      scrolled: false,
      name: 'Basic',
      thickNum: 2, // 선택한 두께 번호
      kindNum: 0, // 선택한 아크릴 두께
      standNum: 0, // 선택한 바닥부품 번호
      printNum: 1, // 선택한 인쇄면 번호
      secPrintNum: 1, // 선택한 두번째 인쇄면 번호
      partsPrintNum: 1, // 선택한 파츠 인쇄면 번호
      itemNum: 1, // 선택한 부자재 번호
      packingNum: 0, // 선택한 조립/포장 번호
      periodNum: 1, // 선택한 제작기간 번호
      keyringKindNum: null, // 선택한 키링 종류 번호
      keyringColorNum: null, // 선택한 키링 색상 번호
      tagColorNum: null, // 군번줄 색상 번호
      wireColorNum: null, // 와이어링 색상 번호
      tokColorNum: null, // 스마트톡 색상 번호
      badgeKindNum: null, // 뱃지 종류 번호
      xLength: 59, // xsize 최대값
      yLength: 29, // ysize 최대값
      previewXsize: 4, // 마우스 오버시 보이는 xsize
      previewYsize: 4, // 마우스 오버시 보이는 ysize
      speechBubble: false, // 도안수량에대한 말풍선 보임,숨김여부
      stand_none: false, // 바닥부품 선택 없음시 안내문구 호출여부
      print_shell_popup: false, // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
      wiring_popup: false, // 부자재 와이어링 선택시 안내문구 호출
      xsize_default: 4,
      ysize_default: 4,
      period_popup: false, // 제작기간 안내문구 호출
      shakerSizeHole: false, // 쉐이커 크기가 5*6 이상일때 구멍개수 제한여부
    }
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      lamp: 'Lamp/lamp',
      type: 'Goods/type',
      quick_order: 'Goods/quick_order',
    }),
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('Goods/periodReset')
      if (to.name === 'Acrylic') {
        this.goodsReset()
        this.numReset()
        this.paramsCheck()
        if (this.type === '아크릴 램프') {
          this.xsize_default = 8
          this.ysize_default = 8
        } else {
          this.xsize_default = 4
          this.ysize_default = 4
        }
        this.location(this.xsize_default, this.ysize_default, this.goods.etc)
        this.getMaterial()
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('Goods/periodReset')
    await this.indexedDB.initializeData()
    await this.getMaterial()
    this.sticky()
    this.numReset()
    this.goodsReset()
    await this.paramsCheck()
    if (this.type === '아크릴 램프') {
      this.xsize_default = 8
      this.ysize_default = 8
    } else {
      this.xsize_default = 4
      this.ysize_default = 4
    }
    this.location(this.xsize_default, this.ysize_default)
    window.addEventListener('scroll', this.detectWindowScrollY)
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  unmounted() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  methods: {
    closeTab() {
      this.stand_none = false
      this.print_shell_popup = false
      this.wiring_popup = false
      this.period_popup = false
    },
    sticky() {
      let elements = document.querySelectorAll('.sticky')
      Stickyfill.add(elements)
    },
    // vuex에 담겨있는 goods값을 초기화
    goodsReset() {
      this.$store.dispatch('Goods/reset')
    },
    // params에 따라 type값 배치
    async paramsCheck() {
      let type = ''
      this.params = this.$route.params.type
      if (this.$route.query.kind === 'hologram') {
        this.selOption('kind', 1, '홀로그램')
      }
      if (this.params === 'Cham') {
        type = '아크릴 키링'
        if (this.quick_order === 'true' || this.quick_order === true) {
          this.selOption('period', 0, '빠른제작')
        }
        this.period_popup = true
      } else if (this.params === 'Stand') {
        type = '아크릴 스탠드'
        if (this.quick_order === 'true' || this.quick_order === true) {
          this.selOption('period', 0, '빠른제작')
        }
        this.selOption('stand', 1, '4cm')
        this.period_popup = true
      } else if (this.params === 'Attach') {
        type = '부착형 아크릴'
        if (this.quick_order === 'true' || this.quick_order === true) {
          this.selOption('period', 0, '빠른제작')
        }
        this.selOption('packing', 0, '묶음포장')
        this.period_popup = true
      } else if (this.params === 'Card') {
        type = '아크릴 카드텍'
        if (this.quick_order === 'true' || this.quick_order === true) {
          this.selOption('period', 0, '빠른제작')
        }
        this.selOption('packing', 0, '묶음포장')
        this.period_popup = true
      } else if (this.params === 'Bookmark') {
        type = '자유형 책갈피'
        this.selOption('packing', 0, '묶음포장')
        this.selOption('thick', 0, '0.5t')
      } else if (this.params === 'LampStand') {
        type = '아크릴 램프'
        this.selOption('packing', 0, '묶음포장')
        this.selOption('thick', 3, '5t')
        // this.getTicketQuantity(type)
      } else if (this.params === 'FloorParts') {
        type = '아크릴 바닥 부품'
        this.selOption('packing', 0, '묶음포장')
        this.selOption('thick', 3, '5t')
      } else if (this.params === 'Block') {
        type = '아크릴 블럭'
        this.selOption('thick', 0, '10t')
        this.selOption('packing', 0, '개별포장')
      } else if (this.params === 'ShakerKeyring') {
        type = '쉐이커 키링'
        this.selOption('thick', 0, '3t')
        this.selOption('packing', 0, '묶음포장')
        this.selOption('second_print', 1, '단면')
        this.selOption('parts_print', 1, '단면')
        this.selOption('bolt', 0, 2)
      }
      await this.$store.dispatch('Goods/typeSet', type)
    },
    // 스크롤 위치에따라 movebar위치 이동
    detectWindowScrollY() {
      const header = document.getElementById(`Basic_pageHeader`)
      let fixed_Header = document.getElementById('fixed_Header')
      if (this.params === 'Cham') {
        this.introHeight = 12400
      } else if (this.params === 'Stand') {
        this.introHeight = 13100
      } else if (this.params === 'Card') {
        this.introHeight = 8700
      } else if (this.params === 'Bookmark') {
        this.introHeight = 8600
      } else if (this.params === 'LampStand') {
        this.introHeight = 9400
      } else if (this.params === 'FloorParts') {
        this.introHeight = 8750
      } else if (this.params === 'Attach') {
        this.introHeight = 11400
      } else if (this.params === 'Block') {
        this.introHeight = 8650
      } else if (this.params === 'ShakerKeyring') {
        this.introHeight = 13550
      }
      this.headerTop = header.offsetTop
      let margin = this.headerTop + this.introHeight

      // 감지 이벤트 메서드
      this.scrolled = window.scrollY > this.headerTop
      if (this.scrolled === true) {
        fixed_Header.classList.add('scrolled')
        // topBtn.style.display = 'block'
      } else {
        fixed_Header.classList.remove('scrolled')
        // topBtn.style.display = 'none'
      }
      if (window.scrollY > 100 && window.scrollY < this.headerTop) {
        this.intro_movebar.left = '140px'
      }
      if (window.scrollY > this.headerTop && window.scrollY < margin) {
        this.intro_movebar.left = '395px'
      }
      if (window.scrollY > margin + 400 && window.scrollY < margin + 1000) {
        this.intro_movebar.left = '655px'
      }
      if (window.scrollY > margin + 1200) {
        this.intro_movebar.left = '920px'
      }
    },
    // intro 메뉴 클릭시 클릭한메뉴 위치로 스크롤이동
    scrollEvent(i) {
      let margin = this.headerTop + this.introHeight
      if (i === 0) {
        window.scroll({
          behavior: 'auto',
          top: 0,
        })
        this.intro_movebar.left = '140px'
      } else if (i === 1) {
        window.scroll({
          behavior: 'auto',
          top: this.headerTop + 100,
        })
        this.intro_movebar.left = '395px'
      } else if (i === 2) {
        window.scroll({
          behavior: 'auto',
          top: margin + 500,
        })
        this.intro_movebar.left = '655px'
      } else if (i === 3) {
        window.scroll({
          behavior: 'auto',
          top: margin + 1300,
        })
        this.intro_movebar.left = '920px'
      }
    },
    async cartAdd() {
      let { goods } = this
      if (this.type === '아크릴 램프') {
        if (goods.stand === '없음') {
          this.Message('아크릴 바닥부품을 선택해주세요.', 0)
          return
        }
        if (goods.stand === '우드 램프(3000k)') {
          if (goods.order_quantity > this.lamp['3000k']) {
            this.Message('재고가 부족합니다.', 0)
            return
          }
        }
        if (goods.stand === '우드 램프(4000k)') {
          if (goods.order_quantity > this.lamp['4000k']) {
            this.Message('재고가 부족합니다.', 0)
            return
          }
        }
        if (goods.stand === '우드 램프(6500k)') {
          if (goods.order_quantity > this.lamp['6500k']) {
            this.Message('재고가 부족합니다.', 0)
            return
          }
        }
      }
      await this.indexedDB.addOrder(goods, this.type)
    },
    numReset() {
      this.thickNum = 2
      this.kindNum = 0
      this.standNum = 0
      this.itemNum = 0
      this.printNum = 1
      this.packingNum = 0
      this.periodNum = 1
    },
    // 옵션 선택
    selOption(type, i, value) {
      if (type === 'thick') {
        this.thickNum = i
        if (i !== 2) {
          this.selOption('kind', 0, '투명')
        }
      } else if (type === 'kind') {
        this.kindNum = i
      } else if (type === 'stand') {
        if (i === 0) {
          this.stand_none = true
        }
        this.standNum = i
      } else if (type === 'print') {
        this.printNum = i
      } else if (type === 'second_print') {
        this.secPrintNum = i
      } else if (type === 'parts_print') {
        this.partsPrintNum = i
      } else if (type === 'item') {
        this.itemNum = i
        if (i === 4 && value === '고리') {
          this.selOption('keyring_kind', 0, '일반키링')
          this.selOption('keyring_color', 0, '실버')
        } else if (i === 1 && value === '뱃지') {
          this.selOption('badgeKind', 0, '핀뱃지')
        } else if (i === 2 && value === '군번줄') {
          this.selOption('tagColor', 0, '일반')
        } else if (i === 5 && value === '와이어링') {
          this.selOption('wiringColor', 0, '빨강')
        } else if (i === 2 && value === '스마트톡') {
          if (this.goods.x < 5 || this.goods.y < 5) {
            this.Message(
              '스마트톡 사이즈(4cm)로 인하여 아크릴 최소 사이즈가 5*5cm로 자동 조정되는점 양해 부탁드립니다.',
              0,
            )
          }
          this.selOption('tokColor', 0, '투명색')
          this.sizeChange(this.goods.x, this.goods.y, value)
        } else {
          this.selOption('keyring_kind', null, null)
          this.selOption('keyring_color', null, null)
        }
      } else if (type === 'keyring_kind') {
        if (i === 0) {
          this.selOption('keyring_color', 0, '실버')
        } else if (i === 7) {
          this.selOption('keyring_color', 2, '로즈골드')
        } else if (i === 8) {
          this.selOption('keyring_color', 0, '실버')
        }
        this.keyringKindNum = i
      } else if (type === 'keyring_color') {
        this.keyringColorNum = i
      } else if (type === 'packing') {
        this.packingNum = i
      } else if (type === 'tagColor') {
        this.tagColorNum = i
      } else if (type === 'wiringColor') {
        this.wireColorNum = i
      } else if (type === 'tokColor') {
        this.tokColorNum = i
      } else if (type === 'badgeKind') {
        this.badgeKindNum = i
      } else if (type === 'period') {
        this.periodNum = i
        this.packingNum = 0
        this.kindNum = 0
      }
      this.$store.dispatch(`Goods/${type}`, value)
    },
    // size
    // 아크릴 크기를 input박스로 바꿀때
    sizeChange(xsize, ysize, etc) {
      let yBox = document.getElementsByClassName('yBox')
      if (this.type === '아크릴 램프') {
        if (xsize >= 60) {
          xsize = 59
        } else if (xsize <= 7) {
          xsize = 8
        }
        if (ysize >= 30) {
          ysize = 29
        } else if (ysize <= 7) {
          ysize = 8
        }
      } else if (this.type === '부착형 아크릴') {
        if (etc === '스마트톡') {
          if (xsize >= 60) {
            xsize = 59
          } else if (xsize <= 4) {
            xsize = 5
          }
          if (ysize >= 30) {
            ysize = 29
          } else if (ysize <= 4) {
            ysize = 5
          }
        } else {
          if (xsize >= 60) {
            xsize = 59
          } else if (xsize <= 3) {
            xsize = 4
          }
          if (ysize >= 30) {
            ysize = 29
          } else if (ysize <= 3) {
            ysize = 4
          }
        }
      } else if (this.type === '아크릴 블럭') {
        if (xsize >= 60) {
          xsize = 59
        } else if (xsize <= 4) {
          xsize = 5
        }
        if (ysize >= 30) {
          ysize = 29
        } else if (ysize <= 4) {
          ysize = 5
        }
      } else if (this.type === '쉐이커 키링') {
        if (xsize > 5 || ysize > 5) {
          if (this.shakerSizeHole === false) {
            this.Message(
              '5*6cm 사이즈보다 크게 제작 될시, 프레임 구멍 2개로 고정되지 않아 프레임 구멍 3개 이상으로 선택 해주셔야 합니다.',
              0,
            )
            this.selOption('bolt', 0, 3)
            this.shakerSizeHole = true
          }
        } else {
          this.shakerSizeHole = false
          if (xsize < 4) {
            xsize = 4
          }
          if (ysize < 4) {
            ysize = 4
          }
        }
      } else {
        if (xsize >= 60) {
          xsize = 59
        } else if (xsize <= 3) {
          xsize = 4
        }
        if (ysize >= 30) {
          ysize = 29
        } else if (ysize <= 3) {
          ysize = 4
        }
      }

      for (let i = 0; i <= this.yLength - 1; i++) {
        if (i < ysize) {
          for (let j = this.xLength - 1; j >= 0; j--) {
            if (j >= xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
            } else if (j < xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FFBDD8'
            }
          }
        } else {
          for (let j = 0; j < this.xLength; j++) {
            yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
          }
        }
      }
      this.$store.dispatch('Goods/pSize', {
        x: xsize,
        y: ysize,
      })
    },
    // 아크릴 크기 table에서 선택했을때
    location(xsize, ysize, etc) {
      let yBox = document.getElementsByClassName('yBox')
      if (this.type === '아크릴 램프') {
        if (xsize <= 7) {
          xsize = 8
        }
        if (ysize <= 7) {
          ysize = 8
        }
      } else if (this.type === '부착형 아크릴') {
        if (etc === '스마트톡') {
          if (xsize >= 60) {
            xsize = 59
          } else if (xsize <= 4) {
            xsize = 5
          }
          if (ysize >= 30) {
            ysize = 29
          } else if (ysize <= 4) {
            ysize = 5
          }
        } else {
          if (xsize >= 60) {
            xsize = 59
          } else if (xsize <= 3) {
            xsize = 4
          }
          if (ysize >= 30) {
            ysize = 29
          } else if (ysize <= 3) {
            ysize = 4
          }
        }
      } else if (this.type === '아크릴 블럭') {
        if (xsize >= 60) {
          xsize = 59
        } else if (xsize <= 4) {
          xsize = 5
        }
        if (ysize >= 30) {
          ysize = 29
        } else if (ysize <= 4) {
          ysize = 5
        }
      } else if (this.type === '쉐이커 키링') {
        if (xsize > 5 || ysize > 5) {
          if (this.shakerSizeHole === false) {
            this.Message(
              '5*6cm 사이즈보다 크게 제작 될시, 프레임 구멍 2개로 고정되지 않아 프레임 구멍 3개 이상으로 선택 해주셔야 합니다.',
              0,
            )
            this.selOption('bolt', 0, 3)
            this.shakerSizeHole = true
          }
        } else {
          this.shakerSizeHole = false
        }
        if (xsize < 4) {
          xsize = 4
        }
        if (ysize < 4) {
          ysize = 4
        }
      } else {
        if (xsize <= 3) {
          xsize = 4
        }
        if (ysize <= 3) {
          ysize = 4
        }
      }

      for (let i = 0; i <= this.yLength - 1; i++) {
        if (i < ysize) {
          for (let j = this.xLength - 1; j >= 0; j--) {
            if (j >= xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
            } else if (j < xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FFBDD8'
            }
          }
        } else {
          for (let j = 0; j < this.xLength; j++) {
            yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
          }
        }
      }
      this.$store.dispatch('Goods/pSize', {
        x: xsize,
        y: ysize,
      })
    },
    // 아크릴 크리 table에 마우스 오버했을때 이벤트
    locationOver(x, y) {
      let t1 = document.getElementsByClassName('t1')
      t1[0].style.opacity = '100%'
      if (x <= 3) {
        x = 4
      }
      if (y <= 3) {
        y = 4
      }
      let yBox1 = document.getElementsByClassName('yBox1')
      for (let i = 0; i <= this.yLength - 1; i++) {
        if (i <= y) {
          for (let j = this.xLength - 1; j >= 0; j--) {
            if (j > x) {
              yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '10%'
            } else if (j <= x) {
              yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '30%'
            }
          }
        } else {
          for (let j = 0; j < this.xLength; j++) {
            yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '10%'
          }
        }
      }
      this.previewXsize = x
      this.previewYsize = y
    },

    // quantity
    // 도안 또는 주문수량 input으로 입력시 이벤트
    quantityChange(i, path, goods_quantity, goods_design) {
      if (i === 0) {
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      } else {
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      }
      if (goods_design < 1 || !goods_design) {
        goods_design = 1
      }
      if (goods_quantity < 1 || !goods_quantity) {
        goods_quantity = 1
      }

      this.$store.dispatch('Goods/quantity', {
        design_quantity: goods_design,
        order_quantity: goods_quantity,
        path,
      })
    },
    // 도안 또는 주문수량 버튼 클릭시 이벤트
    quantityCheck(i, type, path, goods_quantity, goods_design) {
      if (type === 'minus') {
        if (i === 0) {
          if (goods_design > 1) {
            goods_design -= 1
          }
        } else {
          if (goods_quantity > 1) {
            goods_quantity -= 1
          }
        }
        if (goods_design > goods_quantity) {
          goods_design = goods_quantity
        }
      } else {
        if (i === 0) {
          goods_design += 1
        } else {
          goods_quantity += 1
        }
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      }
      this.$store.dispatch('Goods/quantity', {
        design_quantity: goods_design,
        order_quantity: goods_quantity,
        path,
      })
    },
    // 도안파일 선택시 이벤트
    selectFile() {
      let file = document.getElementById('goods_file')
      if (file.files[0].type.match(/zip.*/)) {
        if (file.files[0].size > 15728640) {
          this.Message('파일 크기는 15M 까지만 가능합니다.', 0, 0)
        } else {
          this.$store.dispatch('Goods/file', file.files[0])
        }
      } else {
        this.Message('zip파일 형식이 아닙니다. zip파일 형식으로 업로드 해주세요.', 0, 0)
      }
    },
    // 말풍선 보임
    speechView() {
      this.speechBubble = true
    },
    // 말풍선 숨김
    speechHide() {
      this.speechBubble = false
    },
    // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
    printPopup() {
      this.print_shell_popup = true
    },
    // 부자재 와이어링 선택시 안내문구 호출
    wiringPopup() {
      this.wiring_popup = true
    },
    // 부자재 남은수량 조회
    async getMaterial() {
      await this.axios.get(this.Path.GetMaterial).then((res) => {
        if (res.data.success === true) {
          const materials = res.data.data
          for (let i = 0; i < materials.length; i++) {
            if (materials[i].category === '램프') {
              this.$store.dispatch('Lamp/lamp_quantity_set', materials[i])
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.goodsWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;
  position: relative;
}
.margin {
  width: 100%;
  height: 100px;
}
</style>

<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <h2 v-show="type !== '자유형 책갈피' && type !== '아크릴 관람차' && type !== '쉐이커 키링'">
      아크릴 인쇄면
    </h2>
    <h2 v-show="type === '자유형 책갈피'">책갈피 인쇄면</h2>
    <h2 v-show="type === '아크릴 관람차'">아크릴 관람차 키링 인쇄면</h2>
    <h2 v-show="type === '쉐이커 키링'">쉐이커 앞면 인쇄면</h2>
    <div class="printBox clearFix">
      <div v-if="type === '아크릴 바닥 부품' || type === '쉐이커 키링'">
        <input
          id="printnone"
          type="checkbox"
          :checked="printNum === 0"
          :disabled="printNum === 0"
          @change="checkPrint(0, '없음')"
        /><label for="printnone">인쇄 없음</label>
      </div>
      <ul class="printBtns">
        <li
          class="printBtn"
          :class="{ active: printNum === 1 }"
          @click="checkPrint(1, '단면')"
          @keydown="checkPrint(1, '단면')"
        >
          <div>
            <p>단면</p>
            <span class="section">아크릴→일러스트<br />→화이트순서로 인쇄</span>
            <hr />
            <img src="@/assets/goods/print_단면.png" alt="" />
          </div>
        </li>
        <li
          v-if="type !== '부착형 아크릴'"
          class="printBtn"
          :class="{ active: printNum === 2 }"
          @click="checkPrint(2, '양면')"
          @keydown="checkPrint(2, '양면')"
        >
          <div>
            <p>양면</p>
            <span>아크릴→일러스트<br />→화이트→일러스트순서로 인쇄</span>
            <hr />
            <img src="@/assets/goods/print_양면.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    printNum: {
      type: Number,
    },
    kind: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    checkPrint(i, print) {
      if (this.kind === '투명 쉘' && print === '양면') {
        this.printPopup()
      }
      this.$emit('checkPrint', 'print', i, print)
    },
    printPopup() {
      this.$emit('printPopup')
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .printBox {
    font-weight: 400;

    > .printBtns {
      float: left;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 233px;
          margin: 12px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > span {
            font-size: 12px;
            color: #707070;
            display: block;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            margin-top: 14px;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .printBox {
    font-weight: 400;

    > .printBtns {
      float: left;

      > li {
        width: 185px;
        height: 170px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 158px;
          margin: 12px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 1px;
          }

          > span {
            display: block;
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 100%;
            margin-top: 0px;
          }
        }
      }
    }
  }
}
input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        /* font-weight: 700; */
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}
label {
  color: #707070;
  font-size: 14px;
  margin-bottom: 5px;
}
</style>

<template>
  <div class="rightWrap">
    <div v-if="type === '아크릴 키링'" class="stepWrap">
      <!-- 제작기간설정 -->
      <order-period :path="name" :period-num="periodNum" @check-period="selOption" />
      <!-- 두께 설정 -->
      <thick :path="name" :thick-num="thickNum" @check-thick="selOption" />
      <!-- 아크릴 종류 -->
      <kind
        v-if="thickNum === 2"
        :path="name"
        :kind-num="kindNum"
        :period="goods.period"
        :print="goods.print"
        @check-kind="selOption"
        @print-popup="printPopup"
      />
      <!-- 인쇄면 설정 -->
      <print
        :path="name"
        :print-num="printNum"
        :kind="goods.kind"
        @check-print="selOption"
        @print-popup="printPopup"
      />
      <!-- 부자재 설정 -->
      <item
        :path="name"
        :item-num="itemNum"
        :type="type"
        @check-item="selOption"
        @wiring-popup="wiringPopup"
      />
      <!-- 군번줄 색상 설정 -->
      <dog-tag-color
        v-if="itemNum === 2"
        :path="name"
        :tag-color-num="tagColorNum"
        @check-tag-color="selOption"
      />
      <!-- 와이어링 색상 설정 -->
      <wire-color
        v-if="itemNum === 5"
        :path="name"
        :wire-color-num="wireColorNum"
        @check-wire-color="selOption"
      />
      <!-- 키링 옵션 설정 -->
      <keyring
        v-if="itemNum === 4"
        :path="name"
        :keyring-kind-num="keyringKindNum"
        :keyring-color-num="keyringColorNum"
        @check-kind="selOption"
        @check-color="selOption"
      />
      <!-- 조립/포장 설장 -->
      <packing
        v-if="goods.period !== '빠른제작'"
        :path="name"
        :type="type"
        :packing-num="packingNum"
        @check-packing="selOption"
      />
      <!-- 도안수량, 주문수량 -->
      <quantity
        :path="name"
        :type="type"
        :quantity="goods.order_quantity"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
      />
    </div>
    <div v-else class="stepWrap">
      <!-- size -->
      <special-size :type="type" />
      <!-- 조립/포장 -->
      <special-packing />
      <!-- 도안수량, 주문수량 -->
      <quantity
        :path="name"
        :type="type"
        :quantity="goods.order_quantity"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import thick from '../List/GoodsThick.vue'
import kind from '../List/GoodsKind.vue'
import print from '../List/GoodsPrint.vue'
import item from '../List/GoodsItem.vue'
import dogTagColor from '../List/DogTagColor.vue'
import wireColor from '../List/WiringColor.vue'
import keyring from '../List/GoodsKeyring.vue'
import packing from '../List/GoodsPacking.vue'
import quantity from '../List/GoodsQuantity.vue'
import SpecialSize from '../List/Special/SpecialSize.vue'
import SpecialPacking from '../List/Special/SpecialPacking.vue'
import OrderPeriod from '../List/OrderPeriod.vue'

export default {
  components: {
    thick,
    kind,
    print,
    item,
    dogTagColor,
    wireColor,
    keyring,
    packing,
    quantity,
    SpecialSize,
    SpecialPacking,
    OrderPeriod,
  },
  props: {
    type: {
      type: String,
    },
    thickNum: {
      type: Number,
    },
    kindNum: {
      type: Number,
    },
    printNum: {
      type: Number,
    },
    itemNum: {
      type: Number,
    },
    tagColorNum: {
      type: Number,
    },
    wireColorNum: {
      type: Number,
    },
    periodNum: {
      type: Number,
    },
    packingNum: {
      type: Number,
    },
    keyringKindNum: {
      type: Number,
    },
    keyringColorNum: {
      type: Number,
    },
  },
  data() {
    return {
      name: 'Special',
    }
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
    }),
  },
  methods: {
    // 옵션 선택
    selOption(type, i, value) {
      this.$emit('selOption', type, i, value)
    },

    // quantity
    // 도안 또는 주문수량 input으로 입력시 이벤트
    quantityChange(i, path, goods_quantity) {
      this.$emit('quantityChange', i, path, goods_quantity)
    },
    // 도안 또는 주문수량 버튼 클릭시 이벤트
    quantityCheck(i, type, path, goods_quantity) {
      this.$emit('quantityCheck', i, type, path, goods_quantity)
    },
    // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
    printPopup() {
      this.$emit('printPopup')
    },
    // 부자재 와이어링 선택시 안내문구 호출
    wiringPopup() {
      this.$emit('wiringPopup')
    },
  },
}
</script>

<style scoped lang="scss">
.rightWrap {
  float: right;
  width: 410px;
  height: 1040px;
  overflow-y: auto;
  -ms-overflow-style: none;
  margin-bottom: 100px;

  &::-webkit-scrollbar {
    display: none !important;
  }
}
</style>

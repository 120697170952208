<template>
  <div class="wrap">
    <h1>회원 정보수정</h1>
    <info-check v-if="params === 'check'" @pw-check="pwCheck" />
    <info-complet
      v-else
      :user-info="userInfo"
      :user-phone="userInfo.phone"
      :pw-change-status="pwChangeStatus"
      :phone-status="phoneStatus"
      @pw-change="pwChange"
      @pw-change-btn="pwChangeBtn"
      @pw-change-cancel="pwChangeCancel"
      @phone-status-btn="phoneStatusBtn"
      @phone-certification="phoneCertification"
      @user-info-change="userInfoChange"
      @address-btn="addressBtn"
      @update-user="updateUser"
      @cancel-btn="cancelBtn"
      @delete-user-view="deleteUserView"
    />
    <membership-withdrawal
      v-if="delete_view === true"
      @close-message="closeMessage"
      @delete-user="deleteUser"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import { IMP_CODE } from '../../config/IMP_config'
import InfoCheck from '../../components/MyPage/MyPageCheck.vue'
import InfoComplet from '../../components/MyPage/MyPageComplet.vue'
import MembershipWithdrawal from '../../components/Modal/MembershipWithdrawal.vue'
import Cookies from 'js-cookie'

export default {
  components: {
    InfoCheck,
    InfoComplet,
    MembershipWithdrawal,
  },
  data() {
    return {
      params: '',
      userInfo: '',
      pwChangeStatus: false,
      phoneStatus: 0, // 핸드폰 상태값 (0: 수정, 1: 본인인증, 2: 변경완료)
      delete_view: false,
    }
  },
  watch: {
    $route(to, from) {
      this.paramsCheck()
      this.getUserInfo()
    },
  },
  beforeMount() {
    IMP.init(IMP_CODE)
  },
  mounted() {
    this.paramsCheck()
    this.getUserInfo()
  },
  methods: {
    // 현재 페이지의 params값 체크
    paramsCheck() {
      this.params = this.$route.params.check
    },
    deleteUserView() {
      this.delete_view = true
    },
    closeMessage() {
      this.delete_view = false
    },
    // 회원 탈퇴
    async deleteUser() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      await this.axios.post(this.Path.UserSuspension, {}, config).then((res) => {
        if (res.data.success === true) {
          this.Message('회원탈퇴 처리가 완료되었습니다.', 0)
          this.$store.dispatch('UserInfo/logout')
        }
      })
    },
    // 비밀번호 확인
    pwCheck(pwCheck) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .post(
          this.Path.MyPageCheck,
          {
            user_id: this.user.user_id,
            password: pwCheck,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('확인되었습니다.', 6)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.UserProfilePwCheck(err.response.status), 0, 0)
        })
    },
    // 유저정보를 받아옴
    getUserInfo() {
      if (this.params === 'complet') {
        this.userInfo = { ...this.user }
        this.userInfo.sns_consent = this.user.auth_id.sns_consent
      }
    },
    // 비밀번호 변경
    pwChange(nowPw, newPw, pwCheck) {
      let ver2 = /\s/g // 공백 체크
      let ver4 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,20}$/ // 비밀번호
      if (newPw === '' || newPw === undefined) {
        this.Message('비밀번호를 입력해주세요.', 0, 0)
        return
      }
      if (!ver4.exec(newPw) || ver2.exec(newPw)) {
        this.Message('잘못된 비밀번호 형식 입니다.', 0, 0)
        return
      }
      if (pwCheck !== newPw) {
        this.Message('비밀번호가 일치하지 않습니다.', 0, 0)
        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .patch(
          this.Path.MyPagePwChange,
          {
            name: this.userInfo.name,
            phone: this.userInfo.phone,
            before_password: nowPw,
            after_password: newPw,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('비밀번호가 변경되었습니다.', 1, 0)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.UserProfilePwChange(err.response.status), 0, 0)
        })
    },
    // 비밀번호 변경창 보이기
    pwChangeBtn() {
      this.pwChangeStatus = true
    },
    // 비밀번호 변경 취소
    pwChangeCancel() {
      this.pwChangeStatus = false
    },
    // 핸드폰 상태값 변경
    phoneStatusBtn(i) {
      if (i === 0) {
        this.userInfo.phone = this.user.phone
      } else if (i === 1) {
        this.userInfo.phone = ''
      } else if (i === 3) {
        const config = {
          headers: {
            Authorization: `Bearer ${this.userToken}`,
          },
        }
        this.axios
          .patch(
            this.Path.MyPagePhoneChange,
            {
              phone: this.userInfo.phone,
            },
            config,
          )
          .then((res) => {
            if (res.data.success === true) {
              Cookies.set('user_info', JSON.stringify(this.userInfo))
              this.Message('휴대전화 번호가 변경되었습니다.', 1)
            }
          })
          .catch((err) => {
            this.Message(this.ErrorMessage.UserProfilePhoneChange(err.response.status), 0, 0)
          })
      }
      this.phoneStatus = i
    },
    // 핸드폰 인증
    phoneCertification(phone_1, phone_2, phone_3) {
      let ver1 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/ // 휴대전화번호 정규표현식
      let phone = `${phone_1}${phone_2}${phone_3}`
      if (!ver1.exec(phone)) {
        this.Message('올바른 휴대폰 양식에 맞게 작성해주세요.', 0, 0)
        this.phoneBool = false
        return
      }
      IMP.certification(
        {
          // param
          merchant_uid: `userInfo_${new Date().getTime()}`,
        },
        (rsp) => {
          if (rsp.success) {
            this.axios
              .post(this.Path.PhoneCertification, {
                imp_uid: rsp.imp_uid,
              })
              .then((res) => {
                if (res.data.success === true) {
                  // this.auth_key = res.data.data.auth_key
                  // this.register_name = res.data.data.name
                  // this.register_phone_certifications = true
                  this.Message('인증이 완료되었습니다.', 0)
                  this.phoneStatus = 2
                  this.userInfo.phone = phone
                } else {
                  this.Message('인증에 실패하였습니다.', 0)
                  // this.register_phone_certifications = false
                }
              })
          } else {
            this.Message('인증에 실패하였습니다.', 0)
          }
        },
      )
    },
    // 주소 변경
    addressBtn() {
      let vm = this
      new daum.Postcode({
        oncomplete(data) {
          let addr = '' // 주소 변수
          let extraAddr = '' // 참고항목 변수

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.address
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName
            }
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          vm.userInfo.zip_code = data.zonecode
          vm.userInfo.address = addr
          vm.userInfo.detailed_address = ''
        },
      }).open()
    },
    // 유저 정보 변경
    userInfoChange(type, value) {
      this.userInfo[type] = value
    },
    // 회원 정보 수정 저장
    updateUser() {
      // eslint-disable-next-line no-useless-escape
      let ver3 = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/ // 이메일 정규표현식
      if (!ver3.exec(this.userInfo.email)) {
        this.Message('올바른 이메일 양식에 맞게 작성해주세요.', 0, 0)
        return
      }
      if (!this.userInfo.detailed_address) {
        this.Message('상세주소를 입력해주세요.', 0)
        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      let sns_consent = 0
      if (this.userInfo.sns_consent === true) {
        sns_consent = 1
      }
      this.axios
        .patch(
          this.Path.MyPageUpdate,
          {
            email: this.userInfo.email,
            address: this.userInfo.address,
            detailed_address: this.userInfo.detailed_address,
            zip_code: this.userInfo.zip_code,
            sns_consent,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.userInfo.auth_id.sns_consent = this.userInfo.sns_consent
            Cookies.set('user_info', JSON.stringify(this.userInfo))
            this.Message('회원정보 수정이 완료되었습니다.', 1, 0)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.UserProfileUpdate(err.response.status), 0, 0)
        })
    },
    // 회원정보 수정 취소
    cancelBtn() {
      this.$router.push({
        name: 'index',
      })
    },
  },
  computed: {
    ...mapGetters({
      user: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.wrap {
  width: 1160px;
  margin: 0 auto;
  color: #707070;

  h1 {
    font-size: 26px;
    text-align: center;
    color: #707070;
    font-weight: 700;
    margin-top: 70px;
  }
}
</style>

<template>
  <div class="sideCaution">
    <h1>후면 인쇄(양면 인쇄) 시 주의 사항</h1>
    <!-- type === '아크릴 키링' -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/cham_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '아크릴 카드텍' -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록 하여 배치</span>해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/card_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '아크릴 스탠드' -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/stand_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '자유형 책갈피' -->
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/cham_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '아크릴 바닥 부품' -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/cham_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '아크릴 램프' -->
    <div v-show="type === '아크릴 램프'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/lamp_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '아크릴 블럭' -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/block_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
    <!-- type === '쉐이커 키링' -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap">
      <div>
        <p>후면 레이어를 <span>별도로 생성</span>하여 작업해 주세요.</p>
        <p>앞면 또는 뒷면에 있는 일러스트는 반대편에서도 보일 수 있으니,</p>
        <p>
          일러스트 이미지(전면 인쇄)와 후면 레이어가 <span>완전히 겹치도록</span> 하여 배치해주시기
          바랍니다.
        </p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_sideCaution_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>후면 레이어는 <span>반전된 형태</span>로 인쇄 됩니다.</p>
          <p>
            후면에 텍스트가 들어간 경우에는 <br /><span>'텍스트'가 반전</span>되어 있어야 정상적으로
            인쇄됩니다.
          </p>
        </div>
      </div>
      <div>
        <img src="@/assets/guide/cham_sideCaution_2.png" alt="" />
        <p>
          일러스트 레이어와 후면 레이어가 같을 경우, 일러스트 레이어를 선택 후
          <span>ctrl + J를 눌러 복사</span>합니다.
        </p>
        <p>
          일러스트 레이어와 후면 레이어가 다를 경우, 일러스트 레이어와
          <span>동일한 모양으로 작업</span>합니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.sideCaution {
  text-align: center;
  h1 {
    width: 100%;
  }
  > .typeWrap {
    > div {
      margin-top: 40px;

      > img {
        margin-top: 20px;
      }

      > .img {
        margin-top: 30px;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        > div {
          float: left;
          margin-left: 35px;
          text-align: left;

          &.img_1 {
            margin-top: 120px;
          }
        }
      }
    }
  }
}
</style>

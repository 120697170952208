<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>아크릴 종류</h2>
    <div class="kindBox clearFix">
      <ul class="kindBtns">
        <li
          class="kindBtn"
          :class="{ active: kindNum === 0 }"
          @click="checkKind(0, '투명')"
          @keydown="checkKind(0, '투명')"
        >
          <div>
            <p>기본 투명</p>
            <span>아크릴 → 인쇄</span>
            <hr />
            <img src="@/assets/goods/kind_투명.png" alt="" />
          </div>
        </li>
        <li
          v-if="period !== '빠른제작'"
          class="kindBtn"
          :class="{ active: kindNum === 1 }"
          @click="checkKind(1, '홀로그램')"
          @keydown="checkKind(1, '홀로그램')"
        >
          <div>
            <p>홀로그램</p>
            <span>아크릴 → 인쇄 → <br />홀로그램 → 아크릴</span>
            <hr />
            <img src="@/assets/goods/kind_홀로그램.png" alt="" />
          </div>
        </li>
        <li
          v-if="period !== '빠른제작'"
          class="kindBtn"
          :class="{ active: kindNum === 2 }"
          @click="checkKind(2, '투명 쉘')"
          @keydown="checkKind(2, '투명 쉘')"
        >
          <div>
            <p>투명 쉘</p>
            <span
              >아크릴 → 인쇄 → <span v-show="path === 'Basic'">아크릴</span
              ><span v-show="path === 'Special'"><br />아크릴</span></span
            >
            <hr />
            <img src="@/assets/goods/kind_투명.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    path: {
      type: String,
    },
    kindNum: {
      type: Number,
    },
    period: {
      type: String,
    },
    print: {
      type: String,
    },
  },
  methods: {
    checkKind(i, kind) {
      if (this.print === '양면' && kind === '투명 쉘') {
        this.printPopup()
      }
      this.$emit('checkKind', 'kind', i, kind)
    },
    printPopup() {
      this.$emit('printPopup')
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .kindBox {
    font-weight: 400;

    > .kindBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(2) {
          > div {
            > hr {
              margin-top: 5px;
            }
          }
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 144px;
          margin: 12px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > span {
            font-size: 12px;
            color: #707070;
            display: block;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 23px;
          }

          > img {
            margin-top: 14px;
            width: 100%;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .kindBox {
    font-weight: 400;

    > .kindBtns {
      float: left;

      > li {
        width: 118px;
        height: 170px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          > div {
            > hr {
              margin-top: 20px;
            }
          }
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 90px;
          margin: 12px auto 0;

          > p {
            font-size: 14px;
            color: #707070;
            margin-bottom: 1px;
          }

          > span {
            display: block;
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
